<template>
  <div class="news">
    <div class="overview">
      <div class="title">{{info.title}}</div>
      <div class="desc">
        <span v-if="info.user != null">{{info.user.nick}}</span>
        <span v-if="info.user == null">伊莉丝钻石</span>
        <span>{{info.ct|dateTimeFormats}}</span>
      </div>
      <div class="img">
        <img :src="info.firstpic?info.firstpic.picUrl:''" alt />
      </div>
    </div>
    <div class="image-list" v-for="(item, index) in imageList" :key="index">
      <div :class="item.coarse==1?'text wit':'text'" v-if="item.t===1" @click="titleClick(item)">
        <div :class="item.coarse==1?'title wit':'title'" v-if="item.title===1 && item.center===1">{{item.c}}</div>
        <div :class="item.coarse==1?'sub-title wit':'sub-title'"  v-else-if="item.title===1 && item.center!==1">{{item.c}}</div>
        <div  :class="item.coarse==1?'text wit':'text'" v-else>{{item.c}}</div>
      </div>
      <div class="image" v-if="item.t===2" @click="titleClick(item)">
        <img :src="item.picvideo.picUrl" alt />
      </div>
      <div class="video" v-if="item.t===3">
        <div class="mask" v-show="!item.hideMask">
          <div class="play" @click="play($event,item)">立即观看</div>
        </div>
        <video :src="item.picvideo.videoUrl"></video>
      </div>
      <div class="product" v-if="item.t===4">
        <div class="img">
          <img :src="item.product.pic.picUrl" alt />
        </div>
        <div class="info">
          <div class="info-title">{{item.product.title}}</div>
          <div class="info-desc">{{item.product.desc}}</div>


          <div  class="price">{{item.product.minpriceStr}}</div>
          <div class="btn" @click="goProductInfo(item.product)">去查看</div>

        </div>
      </div>
      <div class="topic" v-if="item.t===5">
        <div class="img">
          <img :src="item.topic.pic.picUrl" alt />
        </div>
        <div class="info">
          <div class="info-title">{{item.topic.title}}</div>
          <div class="info-desc">{{item.topic.c}}</div>
          <div class="btn" @click="goTopic(item.topic)">了解更多</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_NEWS } from "../../service/other";
export default {
  name: "News",
  components: {},
  data() {
    return {
      info: {
        firstpic: {},
        user: {}
      },
      imageList: []
    };
  },
  computed: {},
  created() {
    this.id = this.$route.query.id;
    this.getNews();
  },
  methods: {
    getNews() {
      GET_NEWS({ tid: this.id }).then(res => {
        this.info = res.attachment.data;
        this.imageList = res.attachment.data.imagetexts;
        console.log(this.info)
      });
    },
    titleClick(item) {
      if (item.isherf === 1 && item.herfvar) {
         let tf = item.herfvar.indexOf('https') != -1
         console.log(tf)
          if(tf){
              window.location.href = item.herfvar
          }else{
              window.location.href = "https://" + item.herfvar;
          }
      }
    },
    play(e, item) {
      this.$set(item, "hideMask", true);
      let target = e.target;
      let vedioDom = target.parentNode.nextSibling;
      console.log(target);
      vedioDom.play();
      vedioDom.controls = true;
    },
    goProductInfo(item) {
      console.log("goProductInfo", item);
      this.$router.push({
        path: "/productInfo.html",
        query: { id: item.id }
      });
    },
    goTopic(item) {
      console.log("goTopic", item);
    }
  }
};
</script>
<style lang="less" scoped>
.wit{
  font-weight: bold;
}
.wit a{
  font-weight: bold;
}
.price{
  color:#921d22;
  line-height:20px;
  position: absolute;
  bottom: 5px;
  font-size: 16px;/* no */
}

.news {
  width: 960px;
  margin: 0 auto;
  .overview {
    margin-bottom: 80px;
    .title {
      font-size: 30px; /* no */
      line-height: 1;
      color: #343434;
    }
    .desc {
      font-size: 19px;
      color: #666;
      line-height: 1;
      padding: 40px 0;
      span:first-child {
        padding-right: 40px;
      }
    }
    .img {
      width: 100%;
      img {
        width: 100%;
        display: block;
      }
    }
  }
  .image-list {
    width: 100%;
    overflow: hidden;
    .text {
      .title {
        font-size: 26px; /* no */
        text-align: center;
        color: #343434;
        line-height: 1;
        margin-bottom: 36px; /* no */
        white-space: pre-line;
      }
      .sub-title {
        font-size: 18px; /* no */
        color: #343434;
        margin-bottom: 25px; /* no */
        line-height: 1;
      }
      .text {
        font-size: 16px; /* no */
        margin-bottom: 40px; /* no */
        line-height: 24px; /* no */
        white-space: pre-line;
      }
    }
    .image {
      width: 100%;
      img {
        width: 100%;
        display: block;
        margin-bottom: 40px;
      }
    }
    .video {
      width: 100%;
      background: #000;
      position: relative;
      color: #fff;
      text-align: center;
      margin-bottom: 80px;
      video {
        width: 100%;
        // height: 540px;
        background: #fff;
        // border-radius: 16px;
        display: block;
      }
      .mask {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(0, 0, 0, 0.6);
        z-index: 10;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .play::before {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          background-image: url("../../assets/images/play-white.png");
          background-size: 100% 100%;
          width: 82px;
          height: 82px;
        }
        .play {
          padding-top: 102px;
          position: relative;
          font-size: 24px;
          cursor: pointer;
        }
      }
    }
    .product,
    .topic {
      padding: 70px 42px;
      border: 2px solid rgba(245, 245, 245, 1); /*no*/
      display: flex;
      margin-bottom: 80px;
      .img {
        width: 309px;
        height: 309px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 10px;
          object-fit: cover;
        }
      }
      .info {
        margin-left: 40px;
        flex: 1;
        position: relative;
        .info-title {
          padding: 28px 0 36px;
          line-height: 1;
          color: #343434;
          font-size: 26px; /* no */
        }
        .info-desc {
          line-height: 1.5;
          color: #343434;
          font-size: 16px; /* no */
        }
      }
      .btn {
        position: absolute;
        right: 0;
        bottom: 0px; /* no */
        padding: 0 54px; /* no */
        font-size: 16px; /* no */
        line-height: 56px;
        color: #921d22;
        border: 1px solid #921d22; /*no*/
        border-radius: 28px;
      }
    }
  }
}

@media screen and(max-width:1700px) {
  .news {
    width: 1260px;
    .image-list .topic,
    .image-list .product {
      .img {
        width: 309px; /* no */
        height: 309px; /* no */
      }
    }
    .product .info .info-title {
      padding: 22px 0 20px; /* no */
    }
  }
}

@media screen and(max-width:1500px) {
  .news {
    width: 935px; /* no */
    .image-list .topic,
    .image-list .product,
    .product {
      .img {
        width: 269px; /* no */
        height: 269px; /* no */
      }
    }
  }
}

@media screen and(max-width:1300px) {
  .news {
    width: 892px; /* no */
    .image-list .topic,
    .image-list .product,
    .product {
      .img {
        width: 269px; /* no */
        height: 269px; /* no */
      }
    }
  }
}

@media screen and(max-width:1100px) {
  .news {
    width: 850px; /* no */
    .image-list .topic,
    .image-list .product {
      .img {
        width: 269px; /* no */
        height: 269px; /* no */
      }
    }
  }
}
</style>
